<template>
  <div>
    <div class="d-flex align-items-center justify-content-end mt-3 mb-4">
      <div class="d-flex">
        <b-dropdown variant="secondary">
          <template slot="button-content">
            報名審核
          </template>
          <b-dropdown-item @click="approved">
            報名通過
          </b-dropdown-item>
          <b-dropdown-item @click="rejected">
            報名不通過
          </b-dropdown-item>
        </b-dropdown>
        <div class="ml-2 mr-2">
          <b-dropdown variant="secondary">
            <template slot="button-content">
              報到設定
            </template>
            <b-dropdown-item
              v-for="item in checkinStatusOptions"
              :key="item.value"
              @click="setCheckinResult(item.value)"
            >
              {{ item.text }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <b-button
          v-if="false"
          variant="inverse-primary"
          class="ml-0 ml-xl-4"
          @click="handleExport"
        >
          <span class="mdi mdi-file-excel mr-1"></span>
          匯出 Excel
        </b-button>
      </div>

      <b-input-group class="ml-4" style="width: 320px">
        <b-form-input
          placeholder="搜尋會員名稱、LINE UID、回答內容"
          v-model="query.keyword"
          v-on:keyup.enter="handlePageReset"
        ></b-form-input>
        <b-input-group-append>
          <b-button @click="handlePageReset"
            ><i class="fa fa-search"></i
          ></b-button>
        </b-input-group-append>
      </b-input-group>
    </div>

    <b-table
      responsive
      striped
      hover
      :items="results"
      :fields="fields"
      @sort-changed="handleSort"
    >
      <template #head(checkbox)>
        <b-form-checkbox
          v-model="selectAll"
          @change="toggleSelectAll"
        ></b-form-checkbox>
      </template>
      <template #cell(checkbox)="data">
        <b-form-checkbox
          v-model="data.item.selected"
        ></b-form-checkbox>
      </template>
      <template #cell(avatar_url)="data">
        <router-link :to="{ name: 'CustomerDetailView', params: { customerID: data.item.customer.id } }" >
          <b-avatar
            :src="data.item.customer.avatar_url"
            variant="secondary"
            size="2rem"
          ></b-avatar>
        </router-link>
      </template>
      <template #cell(customer_name)="data">
        <router-link :to="{ name: 'CustomerDetailView', params: { customerID: data.item.customer.id } }" >
          {{ data.item.customer.name }}
        </router-link>
      </template>
      <template #cell(created_at)="data">
        {{ formatDate(data.item.created_at) }}
      </template>
      <template #cell(status)="data">
        {{ statusForDisplay[data.item.status] }}
      </template>
      <template #cell(status_at)="data">
        {{ formatDate(data.item.status_at) }}
      </template>
    </b-table>
    <CustomPagination
      :currentPage="query.page"
      :totalRows="totalRows"
      :perPage="query.per_page"
      @page-change="handlePageChange"
      @per-page-change="handlePerPageChange"
    />
  </div>
</template>

<script>
import formApi from "@/apis/form";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";
import moment from "moment";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";

const initialQuery = {
  page: 1,
  per_page: 10,
  keyword: "",
  sort_by: "created_at",
  order_by: "desc",
};

export default {
  components: {
    CustomPagination,
  },
  data() {
    return {
      totalRows: 0,
      selectAll: false,
      fields: [
        {
          key: "checkbox",
        },
        {
          key: "avatar_url",
          label: "",
          sortable: false,
        },
        {
          key: "customer_name",
          label: "顯示名稱",
          sortable: false,
        },
        {
          key: "created_at",
          label: "填寫日期",
          sortable: true,
        },
        {
          key: "status",
          label: "審核狀態",
          sortable: true,
        },
        {
          key: "status_at",
          label: "審核更新時間",
          sortable: true,
        },
        {
          key: "checkin_status",
          label: "報到狀態",
          formatter: (value) => {
            return this.checkinStatusOptions.find((cso) => {
              return cso.value === value;
            })?.text ?? "";
          },
          sortable: true,
        },
        {
          key: "checkin_status_at",
          label: "報到更新時間",
          sortable: true,
        },
      ],
      results: [],
      query: { ...initialQuery },
      checkinStatusOptions: [
        { value: "attended", text: "已報到" },
        { value: "expired", text: "逾期報到" },
        { value: "canceled", text: "取消報到" },
        { value: "no-show", text: "未報到" },
      ],
    }
  },
  computed: {
    statusForDisplay() {
      return {
        null: '',
        approved: '報名通過',
        rejected: '報名不通過',
      }
    },
  },
  methods: {
    async setCheckinResult(value) {
      let payload = {};
      payload.type = value;
      payload.result_ids = this.results
        .filter((item) => item.selected)
        .map((item) => item.id);
      if (payload.result_ids.length == 0) {
        this.$swal.fire({
          title: "變更失敗",
          type: "warning",
          text: "至少請勾選一名對象",
        });
        return
      }
      try {
        await formApi.checkinResults(
          this.$route.params.id,
          payload
        );
        this.$swal.fire({
          title: "成功",
          type: "success",
          text: "更新成功",
        });
        this.fetchResults();
      } catch (error) {
        console.error(error);
        if (error.status !== 401) {
          this.$swal.fire({
            title: "更新失敗",
            type: "error",
            text: error.response.data.message,
          });
        }
      }
    },
    async rejected() {
      let payload = {};
      payload.result_ids = this.results
        .filter((item) => item.selected)
        .map((item) => item.id);
      if (payload.result_ids.length == 0) {
        this.$swal.fire({
          title: "審核失敗",
          type: "warning",
          text: "至少請勾選一名對象",
        });
        return
      }
      try {
        await formApi.verifyFormRejected(
          this.$route.params.id,
          payload
        );
        this.$swal.fire({
          title: "成功",
          type: "success",
          text: "更新成功",
        });
        this.fetchResults();
      } catch (error) {
        if (error.status !== 401) {
          this.$swal.fire({
            title: "更新失敗",
            type: "error",
            text: error.response.data.message,
          });
        }
      }
    },
    async approved() {
      let payload = {};
      payload.result_ids = this.results
        .filter((item) => item.selected)
        .map((item) => item.id);
      if (payload.result_ids.length == 0) {
        this.$swal.fire({
          title: "審核失敗",
          type: "warning",
          text: "至少請勾選一名對象",
        });
        return
      }
      try {
        await formApi.verifyFormApproved(
          this.$route.params.id,
          payload
        );
        this.$swal.fire({
          title: "成功",
          type: "success",
          text: "變更成功",
        });
        this.fetchResults();
      } catch (error) {
        if (error.status !== 401) {
          this.$swal.fire({
            title: "更新失敗",
            type: "error",
            text: error.response.data.message,
          });
        }
      }
    },
    toggleSelectAll() {
      this.results.forEach((item) => (item.selected = this.selectAll));
    },
    async fetchResults () {
      this.selectAll = false;
      try {
        const response = await formApi.getResults(this.$route.params.id, this.query);
        this.results = response.data.data.map((item) => {
          return {
            ...item,
            selected: false,
          };
        });
        this.totalRows = response.data.meta.total;
        this.form = response.data.form;
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "讀取表單結果錯誤", "error");
      }
    },
    async init() {
      this.$emit('loading');
      this.query = updateQueryFromRoute(
        this.$route,
        this.$store,
        this.query,
      );
      await this.fetchResults();
      this.$emit('done');
    },
    async reset() {
      this.$emit('loading');
      this.$set(this, 'query', { ...initialQuery });
      await this.fetchResults();
      this.$emit('done');
    },
    async runQueryAndUpdateUrl() {
      this.$emit('loading');
      updateUrl(this.query, this.$store, this.$router);
      await this.fetchResults();
      this.$emit('done');
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("YYYY-MM-DD HH:mm");
      }
    },
    handleSort(ctx) {
      this.query.sort_by = ctx.sortBy;
      this.query.order_by = ctx.sortDesc ? "desc" : "asc";
      this.handlePageReset();
    },
    handlePageChange(newPage) {
      this.query.page = newPage;
      this.runQueryAndUpdateUrl();
    },
    handlePerPageChange(newPerPage) {
      this.query.per_page = newPerPage;
      this.query.page = 1;
      this.runQueryAndUpdateUrl();
    },
    async handlePageReset() {
      this.$emit('loading');
      const currentPage = parseInt(this.query.page, 10);

      if (currentPage === 1) {
        updateUrl(this.query, this.$store, this.$router);
      } else {
        this.query.page = 1;
      }

      await this.fetchResults();
      this.$emit('done');
    },
    handleExport() {
      formApi.exportResults(this.form.id).then(() => {
        this.$swal({
          title: "成功",
          text: "可至“我的主控台”查看紀錄及下載檔案",
          showCancelButton: true,
          cancelButtonText: '確認',
          type: "success",
          confirmButtonText: "前往查看",
        }).then((result) => {
          if (result.value) {
            this.$router.push({
              name: "MyConsoleDownloadFileList",
              params: { org_code: this.$route.params.org_code },
            });
          }
        });
      });
    }
  },
}
</script>
